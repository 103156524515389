@font-face {
    font-family: 'Montserrat-Black';
    src: url('Montserrat-Black.ttf');
  }
  @font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url('Montserrat-BlackItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Bold';
    src: url('Montserrat-Bold.ttf');
  }
  @font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('Montserrat-BoldItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('Montserrat-ExtraBold.ttf');
  }
  @font-face {
    font-family: 'Montserrat-ExtraBoldItalic';
    src: url('Montserrat-ExtraBoldItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url('Montserrat-ExtraLight.ttf');
  }
  @font-face {
    font-family: 'Montserrat-ExtraLightItalic';
    src: url('Montserrat-ExtraLightItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Italic';
    src: url('Montserrat-Italic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Light';
    src: url('Montserrat-Light.ttf');
  }
  @font-face {
    font-family: 'Montserrat-LightItalic';
    src: url('Montserrat-LightItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Medium';
    src: url('Montserrat-Medium.ttf');
  }
  @font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url('Montserrat-MediumItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Regular';
    src: url('Montserrat-Regular.ttf');
  }
  @font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('Montserrat-SemiBold.ttf');
  }
  @font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url('Montserrat-SemiBoldItalic.ttf');
  }
  @font-face {
    font-family: 'Montserrat-Thin';
    src: url('Montserrat-Thin.ttf');
  }
  @font-face {
    font-family: 'Montserrat-ThinItalic';
    src: url('Montserrat-ThinItalic.ttf');
  }
          

                          