@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif, "JannaLT-Regular";
  /* font-family: 'JannaLT-Regular'; */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

img::selection {
  background: transparent;
  color: transparent;
}

*::selection {
  background: #f5b750;
  color: #ffffff;
}

*::-moz-selection {
  background: #f5b750;
  color: #ffffff;
}

.partText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.partTextEvent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.partTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.MuiSelect-select.MuiSelect-select{
  padding-right: 10px !important;
  padding-left: 10px !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.error-phone .react-tel-input,
.email-error {
  animation: shake 0.2s ease-in-out 0s 2;
  border: 2px solid #f44336 !important;
}
.react-tel-input .flag-dropdown {
  right: 3rem !important;
}
.react-tel-input .form-control:focus{
  border-color: rgb(156 163 175) !important;
  border-width: 2px !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-tel-input .form-control{
  border-color: rgb(209 213 219) !important;
  border-width: 2px !important;
  outline: none !important;
  box-shadow: none !important;
}

body[dir=rtl] .MuiPaginationItem-icon{
  rotate: 180deg;
}
